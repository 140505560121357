define("@outdoorsyco/ember-shared-data/services/environment", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEVELOPMENT_ORIGINS = {
    dashboard: 'http://localhost:4210',
    search: 'http://localhost:4202',
    reserve: 'http://localhost:4200',
    wheelbase: 'http://localhost:4201'
  };

  class EnvironmentService extends Ember.Service {
    /**
     * Application config
     */
    get config() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

    get originForDashboard() {
      return this.getOriginFor('dashboard');
    }

    get originForReserve() {
      return this.getOriginFor('reserve');
    }

    get originForSearch() {
      return this.getOriginFor('search');
    }
    /**
     * @returns {boolean} Is the current environment set to `development` or `test`.
     */


    get isDevelopmentOrTest() {
      return ['development', 'test'].includes(this.config.environment);
    }
    /**
     * @returns {boolean} Is the current environment set to `development`.
     */


    get isDevelopment() {
      return this.config.environment === 'development';
    }
    /**
     * @returns {boolean} Is the current environment set to `test`.
     */


    get isTest() {
      return this.config.environment === 'test';
    }
    /**
     * @returns {boolean} Is the current environment not `production`.
     */


    get isNotProduction() {
      return this.config.environment !== 'production';
    }
    /**
     * @returns {service} Return Fastboot service
     */


    get fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }
    /**
     * @returns {boolean} Are we running in Fastboot/SSR
     */


    get isFastBoot() {
      return this.fastboot && this.fastboot.isFastBoot;
    }

    get host() {
      return this.isFastBoot ? this.fastboot.request.host : _emberWindowMock.default.location.host;
    }

    get protocol() {
      return this.isFastBoot ? this.fastboot.request.protocol : _emberWindowMock.default.location.protocol;
    }

    get origin() {
      return `${this.protocol}//${this.host}`;
    }
    /**
     * Calculates the default locale for the the current host and environment.
     *
     * @returns {string} Locale
     */


    get defaultLocale() {
      let domainLocales = this.config.domainLocales;
      let host = this.host;
      let locale = domainLocales[host] || 'en-us';

      if (this.isDevelopmentOrTest && locale === 'en-us') {
        locale = 'en';
      }

      return locale;
    }
    /**
     * Calculates the origin of the app depending on the environment we are in.
     *
     * @param {string} app - The name of the app you want the origin for
     * @returns {string} The origin of the app
     */


    getOriginFor(app) {
      if (this.config.environment === 'development') {
        if (this.config.APP.HYPER_LOCAL) {
          if (app !== 'portal') {
            return this.config.APP.outdoorsyHost;
          }

          return this.origin;
        }

        return DEVELOPMENT_ORIGINS[app];
      }

      if (_emberWindowMock.default && _emberWindowMock.default.location && _emberWindowMock.default.location.href.includes('staging')) {
        return 'https://staging.outdoorsy.com';
      }

      if (app !== 'portal' && _emberWindowMock.default && _emberWindowMock.default.location && _emberWindowMock.default.location.href.includes('portal')) {
        return 'https://www.outdoorsy.com';
      }

      return this.origin;
    }

  }

  _exports.default = EnvironmentService;
});