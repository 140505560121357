define("@outdoorsyco/ember-shared-data/utils/activity-diff-finder", ["exports", "@outdoorsyco/ember-shared-data/utils/money"], function (_exports, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityDiffFinder = activityDiffFinder;
  _exports.default = void 0;
  const ulStyle = 'list-style-type: none; padding-left: 16px; margin-bottom: 0px;';
  const oldStyle = 'background-color: #D9534F66; width: fit-content;';
  const newStyle = 'background-color: #246B6259; width: fit-content;';

  function activityDiffFinder(activities, currentCurrency) {
    const allResults = [];

    function separateOldNew(obj) {
      let oldObj = {};
      let newObj = {}; // All the data comes in one object and the only way to separate the old and new data is to look for the keys
      // that start with "old_" or end with "_old" or are just "old". The same for "new".

      function recursiveSeparate(currentObj, oldAcc, newAcc) {
        for (const [key, value] of Object.entries(currentObj)) {
          if (key.startsWith('old_') || key.endsWith('_old') || key === 'old' || key.endsWith('_from')) {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              oldAcc[key] = {};
              recursiveSeparate(value, oldAcc[key], {});
            } else {
              oldAcc[key] = value;
            }
          } else if (key.startsWith('new_') || key.endsWith('_new') || key === 'new' || key.endsWith('_to')) {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              newAcc[key] = {};
              recursiveSeparate(value, {}, newAcc[key]);
            } else {
              newAcc[key] = value;
            }
          } else {
            // Add the key-value pair to both objects if it's not explicitly old or new
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              oldAcc[key] = {};
              newAcc[key] = {};
              recursiveSeparate(value, oldAcc[key], newAcc[key]);
            } else {
              oldAcc[key] = value;
              newAcc[key] = value;
            }
          }
        }
      }

      recursiveSeparate(obj, oldObj, newObj);
      return {
        oldObj,
        newObj
      };
    }

    function createHTML(obj1, obj2, isNew = false) {
      // If the object is empty, return an empty string so that we don't render an empty list.
      if (Object.keys(obj1).length === 0) {
        return '';
      }

      let result = `<ul style="${ulStyle}">`;

      for (const key in obj1) {
        if (key === '_super') continue;
        const trimmedKey = key.replace(/_from$|_to$/, '').replace(/^old_|_old$/, '').replace(/^new_|_new$/, '');
        let obj1Value = obj1[key];
        let obj2Value = obj2[key] || {};
        let newKey = key.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

        if (typeof obj1Value === 'object' && obj1Value !== null) {
          if (key === 'old') {
            obj2Value = obj2['new'];
          } else if (key === 'new') {
            obj2Value = obj2['old'];
          } else if (key.startsWith('old_') || key.endsWith('_old')) {
            obj1Value = obj1[`old_${trimmedKey}`];
            obj2Value = obj2[`new_${trimmedKey}`];
          } else if (key.startsWith('new_') || key.endsWith('_new')) {
            obj1Value = obj1[`new_${trimmedKey}`];
            obj2Value = obj2[`old_${trimmedKey}`];
          } else if (key.endsWith('_from')) {
            obj1Value = obj1[`${trimmedKey}_from`];
            obj2Value = obj2[`${trimmedKey}_to`];
          } else if (key.endsWith('_to')) {
            obj1Value = obj1[`${trimmedKey}_to`];
            obj2Value = obj2[`${trimmedKey}_from`];
          }

          result += `<li><b>${newKey}</b>: \n${createHTML(obj1Value, obj2Value, isNew)}</li>\n`;
        } else {
          let newObj1 = obj1[key];

          if (key === 'security_deposit' || key === 'security_deposit_new' || key === 'security_deposit_old' || key === 'rental_amount' || key === 'price' || key === 'price_new' || key === 'price_old' || key === 'total' || key === 'total_new' || key === 'total_old' || key === 'owner_payout' || key === 'remainder_amount' || key === 'service_fee' || key === 'subtotal' || key === 'tax' || key === 'owner_fee') {
            let style = 'currency';
            let currency = currentCurrency || 'USD';
            let formatedValue = new Intl.NumberFormat('en-US', {
              style,
              currency
            }).format((0, _money.centsToDollars)(newObj1 || 0));
            newObj1 = newObj1 > 0 ? formatedValue : newObj1;
          }

          const keyString = `<b>${newKey}</b>:`;
          const valueString = `${keyString} ${Ember.Handlebars.Utils.escapeExpression(newObj1)}`;

          if (obj1[key] !== obj2[key]) {
            result += `<li style="${isNew ? newStyle : oldStyle}">${valueString}</li>\n`;
          } else {
            result += `<li>${valueString}</li>\n`;
          }
        }
      }

      return result + '</ul>';
    }

    if (typeof activities.toArray === 'function') {
      for (const activity of activities.toArray()) {
        const separated = separateOldNew(activity.data.meta);
        const oldDataFormatted = createHTML(separated.oldObj, separated.newObj, false);
        const newDataFormatted = createHTML(separated.newObj, separated.oldObj, true);
        const resultData = {
          data: activity,
          oldObj: oldDataFormatted,
          newObj: newDataFormatted
        };
        allResults.push(resultData);
      }
    } else {
      const separated = separateOldNew(activities.data.meta);
      const oldDataFormatted = createHTML(separated.oldObj, separated.newObj, false);
      const newDataFormatted = createHTML(separated.newObj, separated.oldObj, true);
      const resultData = {
        data: activities,
        oldObj: oldDataFormatted,
        newObj: newDataFormatted
      };
      return resultData;
    }

    return allResults;
  }

  var _default = Ember.Helper.helper(activityDiffFinder);

  _exports.default = _default;
});