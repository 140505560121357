define("@outdoorsyco/ember-shared-data/components/recursive-object-list/component", ["exports", "@outdoorsyco/ember-shared-data/components/recursive-object-list/template", "@outdoorsyco/ember-shared-data/utils/activity-diff-finder"], function (_exports, _template, _activityDiffFinder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ul',
    session: Ember.inject.service(),
    activity: Ember.computed('items', function () {
      return (0, _activityDiffFinder.activityDiffFinder)(this.get('items'), this.get('session.currentCurrency'));
    })
  });

  _exports.default = _default;
});