define("@outdoorsyco/ember-shared-data/models/discount-code", ["exports", "ember-data", "moment", "@outdoorsyco/ember-shared-data/utils/states-provinces"], function (_exports, _emberData, _moment, _statesProvinces) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  let DiscountCode = (_dec = belongsTo('user'), _dec2 = belongsTo('user'), _dec3 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec4 = attr('string'), _dec5 = attr('string', {
    defaultValue: 'Outdoorsy'
  }), _dec6 = attr('string'), _dec7 = attr('centsToDollars'), _dec8 = attr(), _dec9 = attr(), _dec10 = attr(), _dec11 = attr(), _dec12 = attr(), _dec13 = attr(), _dec14 = attr('string', {
    defaultValue: 'shopping'
  }), _dec15 = attr('string', {
    defaultValue: 'amount'
  }), _dec16 = attr('string', {
    defaultValue: 'total'
  }), _dec17 = attr('number'), _dec18 = attr('boolean', {
    defaultValue: false
  }), _dec19 = attr('string', {
    defaultValue: 'owner_payout'
  }), _dec20 = attr('boolean'), _dec21 = attr('boolean', {
    defaultValue: true
  }), _dec22 = attr('number'), _dec23 = attr('number'), _dec24 = attr('number'), _dec25 = attr('momentDate'), _dec26 = attr('momentDate'), _dec27 = attr('string'), _dec28 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec29 = attr('array', {
    defaultValue() {
      return [];
    }

  }), _dec30 = attr('boolean', {
    defaultValue() {
      return false;
    }

  }), _dec31 = Ember.computed.equal('amountType', 'amount'), _dec32 = Ember.computed.equal('amountType', 'percentage'), _dec33 = Ember.computed.lte('redeemable', 0), _dec34 = Ember.computed.lte('redeemablePerPerson', 0), _dec35 = attr('string'), _dec36 = Ember.computed('vehicleState', 'tempCountry'), _dec37 = Ember.computed('from', 'to'), (_class = class DiscountCode extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "rentalOwner", _descriptor, this);

      _initializerDefineProperty(this, "createdBy", _descriptor2, this);

      _initializerDefineProperty(this, "locationIds", _descriptor3, this);

      _initializerDefineProperty(this, "createdById", _descriptor4, this);

      _initializerDefineProperty(this, "createdByName", _descriptor5, this);

      _initializerDefineProperty(this, "code", _descriptor6, this);

      _initializerDefineProperty(this, "amount", _descriptor7, this);

      _initializerDefineProperty(this, "from", _descriptor8, this);

      _initializerDefineProperty(this, "to", _descriptor9, this);

      _initializerDefineProperty(this, "shopFrom", _descriptor10, this);

      _initializerDefineProperty(this, "shopTo", _descriptor11, this);

      _initializerDefineProperty(this, "travelFrom", _descriptor12, this);

      _initializerDefineProperty(this, "travelTo", _descriptor13, this);

      _initializerDefineProperty(this, "dateApplied", _descriptor14, this);

      _initializerDefineProperty(this, "amountType", _descriptor15, this);

      _initializerDefineProperty(this, "applyType", _descriptor16, this);

      _initializerDefineProperty(this, "lengthOfStay", _descriptor17, this);

      _initializerDefineProperty(this, "automaticallyApply", _descriptor18, this);

      _initializerDefineProperty(this, "applyToField", _descriptor19, this);

      _initializerDefineProperty(this, "archived", _descriptor20, this);

      _initializerDefineProperty(this, "active", _descriptor21, this);

      _initializerDefineProperty(this, "redeemable", _descriptor22, this);

      _initializerDefineProperty(this, "redeemablePerPerson", _descriptor23, this);

      _initializerDefineProperty(this, "redeemed", _descriptor24, this);

      _initializerDefineProperty(this, "created", _descriptor25, this);

      _initializerDefineProperty(this, "updated", _descriptor26, this);

      _initializerDefineProperty(this, "vehicleState", _descriptor27, this);

      _initializerDefineProperty(this, "vehicleType", _descriptor28, this);

      _initializerDefineProperty(this, "rentalCategory", _descriptor29, this);

      _initializerDefineProperty(this, "firstTripOnly", _descriptor30, this);

      _initializerDefineProperty(this, "isDollarAmount", _descriptor31, this);

      _initializerDefineProperty(this, "isPercentage", _descriptor32, this);

      _initializerDefineProperty(this, "isUnlimited", _descriptor33, this);

      _initializerDefineProperty(this, "isUnlimitedPerPerson", _descriptor34, this);

      _initializerDefineProperty(this, "tempCountry", _descriptor35, this);
    }

    get country() {
      return (0, _statesProvinces.countryFromState)(this.get('vehicleState')) || this.get('tempCountry');
    }

    get isActive() {
      if (!this.get('active')) {
        return false;
      }

      let to = this.get('to');

      if (typeof to === 'string') {
        to = (0, _moment.default)(this.get('to'));
      }

      if (!this.get('isNew') && to) {
        let now = (0, _moment.default)();
        return to.endOf('day').isSameOrAfter(now);
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "rentalOwner", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locationIds", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "createdById", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdByName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "code", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "from", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "to", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "shopFrom", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "shopTo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "travelFrom", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "travelTo", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dateApplied", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "amountType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "applyType", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "lengthOfStay", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "automaticallyApply", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "applyToField", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "archived", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "redeemable", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "redeemablePerPerson", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "redeemed", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "created", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "updated", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "vehicleState", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "vehicleType", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "rentalCategory", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "firstTripOnly", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "isDollarAmount", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "isPercentage", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "isUnlimited", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "isUnlimitedPerPerson", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "tempCountry", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "country", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "country"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype)), _class));
  _exports.default = DiscountCode;
});