define("@outdoorsyco/ember-shared-data/components/approved-drivers-list/component", ["exports", "@outdoorsyco/ember-shared-data/components/approved-drivers-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    user: null,
    booking: null,
    showErrors: true,
    sendingUploadEmail: false,
    verificationUploadSent: false,
    intl: Ember.inject.service(),
    prompt: Ember.inject.service(),
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    actions: {
      async updateBirthdate(driver) {
        await this.prompt.show('driver-missing-fields', {
          driver: driver,
          booking: this.booking
        }, {
          minWidth: 100
        });
        this.booking.reload();
      },

      doDrivingCheck(driver, options) {
        if (this.doDrivingCheck) {
          this.get('doDrivingCheck')(driver, options);
        }
      },

      showDriverChecksForDriver(driver) {
        if (this.selectedDriver === driver) {
          driver = null;
        }

        this.set('selectedDriver', driver);
      },

      requestVerificationUploadEmail() {
        this.set('sendingUploadEmail', true);
        let data = {
          template: 'verify_upload',
          user_id: Number(this.get('user.id'))
        };
        this.apiRequest.request('emails/resend', {
          type: 'POST',
          data: JSON.stringify(data)
        }).then(() => {
          this.setProperties({
            sendingUploadEmail: false,
            verificationUploadSent: true
          });
        });
      },

      driverScreening(driver) {
        Ember.set(driver, 'screenLoading', true);
        this.apiRequest.request(`verify/driver/${driver.id}/screening`).then(response => {
          Ember.set(driver, 'screenLoading', false);

          if (['new', 'expired'].indexOf(response.status) !== -1 && response.inviteLink !== '') {
            this.set('screeningUrl', response.inviteLink);
          } else {
            if (driver) {
              Ember.set(driver, 'status', response.status);
            }

            if (response.message) {
              this.flashMessages.success(response.message);
            }
          }
        }).catch(() => {
          Ember.set(driver, 'screenLoading', false);
          this.flashMessages.danger(this.intl.t('comp-approved-drivers-error'));
        });
      }

    }
  });

  _exports.default = _default;
});