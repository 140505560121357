define("@outdoorsyco/ember-shared-data/components/recursive-object-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkVIUSzn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"activity\",\"oldObj\"]],[24,[\"activity\",\"newObj\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"html-safe\",[[24,[\"activity\",\"oldObj\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"html-safe\",[[24,[\"activity\",\"oldObj\"]]],null],false],[0,\"\\n  \"],[1,[28,\"html-safe\",[[24,[\"activity\",\"newObj\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/recursive-object-list/template.hbs"
    }
  });

  _exports.default = _default;
});