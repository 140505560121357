define("@outdoorsyco/ember-shared-data/services/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    async subscribe(user, type) {
      if (!user) {
        return false;
      }

      let ownerId = user.get('id');
      let msg = this.intl.t('subscription-message-prompt-subscribe');

      if (window.confirm(msg)) {
        let sub = this.store.createRecord('subscription', {
          userId: ownerId,
          type: type
        });

        try {
          await sub.save();
          this.flashMessages.success(this.intl.t('subscription-created'));
          return true;
        } catch (e) {
          console.log(e);
          this.flashMessages.danger(this.intl.t('subscription-error-create'));
        }
      }

      return false;
    },

    async cancel(subscription) {
      if (!subscription) {
        return;
      }

      let msg = this.intl.t('subscription-message-prompt-cancel');

      if (window.confirm(msg)) {
        await subscription.destroyRecord();
        this.flashMessages.success(this.intl.t('subscription-cancelled'));
        return true;
      }

      return false;
    },

    async getSubscriptions(type, params) {
      if (!params) {
        params = {};
      }

      return this.store.query('subscription', {
        user_id: params.ownerId,
        only_active: true,
        type: type
      });
    }

  });

  _exports.default = _default;
});