define("@outdoorsyco/ember-shared-data/services/rental-type-options", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RentalTypeOptions = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.lastValue)('fetchTypes'), (_class = class RentalTypeOptions extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _defineProperty(this, "cachedCountry", void 0);

      _defineProperty(this, "cachedLocale", void 0);

      _defineProperty(this, "cachedOwnerId", void 0);

      _initializerDefineProperty(this, "options", _descriptor4, this);

      _initializerDefineProperty(this, "fetchTypes", _descriptor5, this);
    }

    get hasOptions() {
      return Boolean(this.options.length);
    }
    /**
     * fetches translated rental types and returns collection
     * @param {object} options - hash of possible options to filter by
     * @param {string} options.locale - optional locale language to translate the labels in
     * @param {string} options.country - optional country by which to filter available types (some countries lists differ)
     * @param {string} options.ownerId - optionally pass an owner ID to restrict the results to the owners inventory
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return Ember.A();
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchTypes", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return function* (options = {}) {
        const locales = this.intl.locale || [];
        const locale = options.locale || locales[0] || 'en-us';
        this.user = yield this.session.currentAccount;
        const country = options.country || Ember.get(this, 'user.country');
        const {
          rentalCategory,
          ownerId
        } = options;
        const makeRequest = !this.hasOptions || this.cachedLocale !== locale || this.cachedCountry !== country || this.cachedRentalCategory !== rentalCategory || this.cachedOwnerId !== ownerId;

        if (makeRequest) {
          let queryParams = {
            locale: options.locale || locale
          };

          if (country) {
            queryParams.country = country;
          }

          if (ownerId) {
            queryParams.owner_id = ownerId;
          }

          if (rentalCategory) {
            queryParams.rental_category = rentalCategory;
          }

          this.setProperties({
            cachedRentalCategory: rentalCategory,
            cachedCountry: country,
            cachedLocale: locale,
            cachedOwnerId: ownerId
          });
          return yield this.store.query('rental-type', queryParams);
        }

        return this.options;
      };
    }
  })), _class));
  _exports.default = RentalTypeOptions;
});